import { defineStore } from 'pinia';

const STORE_NAME = 'Snippet';

export const useSnippetStore = defineStore(STORE_NAME, {
  state: () => ({
    $inSnippetMode: false,
    inSuggestionMode: false,
    $hasNextStop: false,
    $hasPreviousStop: false,
    $cancel: null as (() => void) | null,
    $next: null as (() => void) | null,
    $prev: null as (() => void) | null,
    $focus: null as (() => void) | null,
    $nextSuggestion: null as (() => void) | null,
    $prevSuggestion: null as (() => void) | null,
  }),

  getters: {
    inSnippetMode: (state) => state.$inSnippetMode,
    getSuggestionMode: (state) => state.inSuggestionMode,
    hasNextStop: (state) => state.$hasNextStop,
    hasPreviousStop: (state) => state.$hasPreviousStop,
  },

  actions: {
    changeMode(
      cancel?: () => void,
      next?: () => void,
      prev?: () => void,
      focus?: () => void
    ) {
      if (cancel) this.$cancel = cancel;
      if (next) this.$next = next;
      if (prev) this.$prev = prev;
      if (focus) this.$focus = focus;
    },

    setSnippetMode(snippetMode: boolean) {
      this.$inSnippetMode = snippetMode;
    },

    setHasNext(hasNext: boolean) {
      this.$hasNextStop = hasNext;
    },

    setHasPrevious(hasPrevious: boolean) {
      this.$hasPreviousStop = hasPrevious;
    },

    suggestionMode(enabled: boolean) {
      this.inSuggestionMode = enabled;
    },

    setSuggestionNav(next: () => void, prev: () => void) {
      this.$nextSuggestion = next;
      this.$prevSuggestion = prev;
    },

    next() {
      if (this.$focus) this.$focus();
      if (this.inSnippetMode && !this.hasNextStop) this.cancel();
      if (this.$next) this.$next();
    },

    prev() {
      if (this.$focus) this.$focus();
      if (this.inSnippetMode && !this.hasPreviousStop) this.cancel();
      if (this.$prev) this.$prev();
    },

    nextSuggestion() {
      if (this.inSuggestionMode && this.$nextSuggestion) this.$nextSuggestion();
    },

    prevSuggestion() {
      if (this.inSuggestionMode && this.$prevSuggestion) this.$prevSuggestion();
    },

    cancel() {
      if (this.$cancel) {
        this.$cancel();
      }

      this.stop();
    },

    stop() {
      this.$inSnippetMode = false;
      this.inSuggestionMode = false;
      if (this.$cancel) this.$cancel = null;
      if (this.$next) this.$next = null;
      if (this.$prev) this.$prev = null;
      if (this.$focus) this.$focus = null;
      if (this.$nextSuggestion) this.$nextSuggestion = null;
      if (this.$prevSuggestion) this.$prevSuggestion = null;
    },
  },
});
